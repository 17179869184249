import React from "react"
import Layout from "@layout/Layout"
import Header from "@layout/Header/Header"
import { graphql } from "gatsby"
import { Container, Box, Flex, Heading, css, Link, Text } from "theme-ui"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { StaticImage } from "gatsby-plugin-image"
import "react-tabs/style/react-tabs.css"
import "./post.css"

const PostTemplate = props => {
  const { data } = props
  const {
    tERM_KN_V,
    fot_11,
    fot_12,
    fot_13,
    fot_14,
    fot_15,
    fot_16,
    fot_17,
    fot_18,
    fot_19,
    fot_110,
    sT_TUSZ,
    sKU,
    webshop__r,
    r_VIDLE_R_S,
  } = data.item
  const {
    agyCsap_gyaz_s,
    agyKiszerel_s,
    agytestAnyaga,
    _ll_that_,
    _tm_r_,
    bCD,
    be_p_t_sJellege,
    be_p_t_siMagass_g,
    befog_,
    befog___tm_r_,
    beny_l_s,
    bilincsHelyzet,
    bonthat_L_nct_ny_rok,
    bowdenBevezet_s,
    bowdent_pus,
    bRAND,
    csap_gyaz_sFajt_ja,
    csap_gyh_z,
    cs_szeAnyaga,
    els_V_lt_Bilincs__tm_r_,
    els_V_lt_Fokozatsz_m,
    els_V_lt_R_gz_t_sM_dja,
    els_V_lt_karFokozatsz_ma,
    fejcs___tm_r_,
    f_kKiszerel_s,
    f_kKompatibilit_s,
    f_kT_pus,
    f_kkarBilincs__tm_r_,
    f_kkarBowdenvezet_s,
    f_kkarKiszerel_s,
    f_kkarKompatibilit_s,
    f_kkarT_pus,
    felhaszn_l_siTer_let,
    fogasker_ksorR_gz_t_se,
    fogaskoszor_R_gz_t_se,
    fogsz_m,
    fogsz_mMaxK_l_nbs_g,
    fokozatsz_m,
    forg_spont,
    hajt_karHossz,
    h_trahajl_s,
    h_ts_V_lt_Fokozatsz_m,
    h_ts_V_lt_R_gz_t_sM_dja,
    h_ts_V_lt_karFokozatsz_m,
    helyzet,
    hosszEls_,
    hosszH_ts_,
    kan_lHossz,
    kazettatestT_pus,
    kezeltL_nct_ny_rokSz_ma,
    kioszt_s,
    kompatibilisF_k,
    kompatibilit_s,
    k_nusz__tm_r_,
    korm_nyAnyaga,
    korm_nyT_pus,
    korm_nysz_rHossza,
    k_z_pr_szTengelyKapcsolat,
    l_nct_ny_rokSz_ma,
    legnagyobbKezeltL_ncker_k,
    legnagyobbL_nct_ny_r,
    lyuksz_m,
    n_gysz_gtengelySzabv_nya,
    nyeregR_gz_t_se,
    nyeregcs_Anyaga,
    nyeregcs_Hossz,
    ped_lCsap_gyaz_sa,
    ped_lKiszerel_s,
    ped_lmenet,
    ped_lt_pus,
    peremMagass_g,
    rug_s,
    sebess_g,
    stopliszabv_ny,
    sz_less_g,
    sz_n,
    sz_g,
    teljesKapacit_s,
    tengelyKapcsolat,
    tengelyT_pusa,
    tengelyhossz,
    v_lt_karBilincs__tm_r_,
    v_lt_karR_gz_t_sHelye,
    v_lt_karR_gz_t_sM_dja,
    v_lt_kompatibilit_s,
    villanyak,
    villasaruT_vols_g,
  } = data.tulajdonsagok

  return (
    <Layout>
      <Header />
      <Container sx={{ mt: "10rem" }}>
        <Flex
          sx={{
            columnGap: "3rem",
            flexDirection: ["column", "column", "column", "row"],
          }}
        >
          <Box sx={{ flexBasis: ["1", "1/2"] }}>
            <Tabs>
              {fot_11 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_11.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_12 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_12.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_13 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_13.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_14 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_14.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_15 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_15.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_16 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_16.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_17 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_17.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_18 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_18.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_19 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_19.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              {fot_110 && (
                <TabPanel>
                  <Box
                    sx={{
                      backgroundImage:
                        "url(" + fot_110.replace("w_200", "w_750") + ")",
                      backgroundRepeat: "no-repeat",
                      width: "auto",
                      height: ["20rem", "26rem"],
                      backgroundSize: "100%",
                    }}
                  />
                </TabPanel>
              )}
              <TabList>
                {fot_11 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_11 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_12 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_12 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_13 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_13 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_14 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_14 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_15 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_15 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_16 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_16 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_17 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_17 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_18 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_18 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_19 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_19 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
                {fot_110 && (
                  <Tab>
                    <Box
                      sx={{
                        backgroundImage: "url(" + fot_110 + ")",
                        backgroundRepeat: "no-repeat",
                        width: ["5rem", "6rem"],
                        height: ["4rem", "5rem"],
                        backgroundSize: "100%",
                      }}
                    />
                  </Tab>
                )}
              </TabList>
            </Tabs>
          </Box>
          <Box sx={{ flexBasis: ["1", "1/2"] }}>
            <Heading>{tERM_KN_V}</Heading>
            {sT_TUSZ === "ELADVA\n WEBSHOPBA FELTÖLTVE" ? (
              <Heading sx={{ fontWeight: "800" }}>ELADVA</Heading>
            ) : (
              <Heading sx={{ fontWeight: "800" }}>{webshop__r} FT</Heading>
            )}
            <Box>
              <Text>{r_VIDLE_R_S}</Text>
            </Box>
            <Text>SKU: {sKU}</Text>
            {/* Generated from spreadsheet "Draft" */}
            <table>
              {agyCsap_gyaz_s && (
                <tr>
                  <th>agy csapágyazás:</th>
                  <td>{agyCsap_gyaz_s}</td>
                </tr>
              )}
              {agyKiszerel_s && (
                <tr>
                  <th>Agy kiszerelés:</th>
                  <td>{agyKiszerel_s}</td>
                </tr>
              )}
              {agytestAnyaga && (
                <tr>
                  <th>agytest anyaga:</th>
                  <td>{agytestAnyaga}</td>
                </tr>
              )}
              {_ll_that_ && (
                <tr>
                  <th>állítható:</th>
                  <td>{_ll_that_}</td>
                </tr>
              )}
              {_tm_r_ && (
                <tr>
                  <th>átmérő:</th>
                  <td>{_tm_r_}</td>
                </tr>
              )}
              {bCD && (
                <tr>
                  <th>BCD:</th>
                  <td>{bCD}</td>
                </tr>
              )}
              {be_p_t_sJellege && (
                <tr>
                  <th>beépítés jellege:</th>
                  <td>{be_p_t_sJellege}</td>
                </tr>
              )}
              {be_p_t_siMagass_g && (
                <tr>
                  <th>beépítési magasság:</th>
                  <td>{be_p_t_siMagass_g}</td>
                </tr>
              )}
              {befog_ && (
                <tr>
                  <th>befogó:</th>
                  <td>{befog_}</td>
                </tr>
              )}
              {befog___tm_r_ && (
                <tr>
                  <th>befogó átmérő:</th>
                  <td>{befog___tm_r_}</td>
                </tr>
              )}
              {beny_l_s && (
                <tr>
                  <th>benyúlás:</th>
                  <td>{beny_l_s}</td>
                </tr>
              )}
              {bilincsHelyzet && (
                <tr>
                  <th>Bilincs helyzet:</th>
                  <td>{bilincsHelyzet}</td>
                </tr>
              )}
              {bonthat_L_nct_ny_rok && (
                <tr>
                  <th>Bontható lánctányérok:</th>
                  <td>{bonthat_L_nct_ny_rok}</td>
                </tr>
              )}
              {bowdenBevezet_s && (
                <tr>
                  <th>Bowden bevezetés:</th>
                  <td>{bowdenBevezet_s}</td>
                </tr>
              )}
              {bowdent_pus && (
                <tr>
                  <th>bowdentípus:</th>
                  <td>{bowdent_pus}</td>
                </tr>
              )}
              {bRAND && (
                <tr>
                  <th>Brand:</th>
                  <td>{bRAND}</td>
                </tr>
              )}
              {csap_gyaz_sFajt_ja && (
                <tr>
                  <th>Csapágyazás fajtája:</th>
                  <td>{csap_gyaz_sFajt_ja}</td>
                </tr>
              )}
              {csap_gyh_z && (
                <tr>
                  <th>csapágyház:</th>
                  <td>{csap_gyh_z}</td>
                </tr>
              )}
              {cs_szeAnyaga && (
                <tr>
                  <th>csésze anyaga:</th>
                  <td>{cs_szeAnyaga}</td>
                </tr>
              )}
              {els_V_lt_Bilincs__tm_r_ && (
                <tr>
                  <th>Első váltó bilincs átmérő:</th>
                  <td>{els_V_lt_Bilincs__tm_r_}</td>
                </tr>
              )}
              {els_V_lt_Fokozatsz_m && (
                <tr>
                  <th>Első váltó fokozatszám:</th>
                  <td>{els_V_lt_Fokozatsz_m}</td>
                </tr>
              )}
              {els_V_lt_R_gz_t_sM_dja && (
                <tr>
                  <th>Első váltó rögzítés módja:</th>
                  <td>{els_V_lt_R_gz_t_sM_dja}</td>
                </tr>
              )}
              {els_V_lt_karFokozatsz_ma && (
                <tr>
                  <th>Első váltókar fokozatszáma:</th>
                  <td>{els_V_lt_karFokozatsz_ma}</td>
                </tr>
              )}
              {fejcs___tm_r_ && (
                <tr>
                  <th>fejcső átmérő:</th>
                  <td>{fejcs___tm_r_}</td>
                </tr>
              )}
              {f_kKiszerel_s && (
                <tr>
                  <th>Fék kiszerelés:</th>
                  <td>{f_kKiszerel_s}</td>
                </tr>
              )}
              {f_kKompatibilit_s && (
                <tr>
                  <th>fék kompatibilitás:</th>
                  <td>{f_kKompatibilit_s}</td>
                </tr>
              )}
              {f_kT_pus && (
                <tr>
                  <th>Fék típus:</th>
                  <td>{f_kT_pus}</td>
                </tr>
              )}
              {f_kkarBilincs__tm_r_ && (
                <tr>
                  <th>Fékkar bilincs átmérő:</th>
                  <td>{f_kkarBilincs__tm_r_}</td>
                </tr>
              )}
              {f_kkarBowdenvezet_s && (
                <tr>
                  <th>Fékkar bowdenvezetés:</th>
                  <td>{f_kkarBowdenvezet_s}</td>
                </tr>
              )}
              {f_kkarKiszerel_s && (
                <tr>
                  <th>Fékkar kiszerelés:</th>
                  <td>{f_kkarKiszerel_s}</td>
                </tr>
              )}
              {f_kkarKompatibilit_s && (
                <tr>
                  <th>fékkar kompatibilitás:</th>
                  <td>{f_kkarKompatibilit_s}</td>
                </tr>
              )}
              {f_kkarT_pus && (
                <tr>
                  <th>Fékkar típus:</th>
                  <td>{f_kkarT_pus}</td>
                </tr>
              )}
              {felhaszn_l_siTer_let && (
                <tr>
                  <th>Felhasználási terület:</th>
                  <td>{felhaszn_l_siTer_let}</td>
                </tr>
              )}
              {fogasker_ksorR_gz_t_se && (
                <tr>
                  <th>fogaskeréksor rögzítése:</th>
                  <td>{fogasker_ksorR_gz_t_se}</td>
                </tr>
              )}
              {fogaskoszor_R_gz_t_se && (
                <tr>
                  <th>fogaskoszorú rögzítése:</th>
                  <td>{fogaskoszor_R_gz_t_se}</td>
                </tr>
              )}
              {fogsz_m && (
                <tr>
                  <th>Fogszám:</th>
                  <td>{fogsz_m}</td>
                </tr>
              )}
              {fogsz_mMaxK_l_nbs_g && (
                <tr>
                  <th>Fogszám max különbség:</th>
                  <td>{fogsz_mMaxK_l_nbs_g}</td>
                </tr>
              )}
              {fokozatsz_m && (
                <tr>
                  <th>fokozatszám:</th>
                  <td>{fokozatsz_m}</td>
                </tr>
              )}
              {forg_spont && (
                <tr>
                  <th>forgáspont:</th>
                  <td>{forg_spont}</td>
                </tr>
              )}
              {hajt_karHossz && (
                <tr>
                  <th>Hajtókar hossz:</th>
                  <td>{hajt_karHossz}</td>
                </tr>
              )}
              {h_trahajl_s && (
                <tr>
                  <th>hátrahajlás:</th>
                  <td>{h_trahajl_s}</td>
                </tr>
              )}
              {h_ts_V_lt_Fokozatsz_m && (
                <tr>
                  <th>Hátsó váltó fokozatszám:</th>
                  <td>{h_ts_V_lt_Fokozatsz_m}</td>
                </tr>
              )}
              {h_ts_V_lt_R_gz_t_sM_dja && (
                <tr>
                  <th>Hátsó váltó rögzítés módja:</th>
                  <td>{h_ts_V_lt_R_gz_t_sM_dja}</td>
                </tr>
              )}
              {h_ts_V_lt_karFokozatsz_m && (
                <tr>
                  <th>Hátsó váltókar fokozatszám:</th>
                  <td>{h_ts_V_lt_karFokozatsz_m}</td>
                </tr>
              )}
              {helyzet && (
                <tr>
                  <th>helyzet:</th>
                  <td>{helyzet}</td>
                </tr>
              )}
              {hosszEls_ && (
                <tr>
                  <th>hossz első:</th>
                  <td>{hosszEls_}</td>
                </tr>
              )}
              {hosszH_ts_ && (
                <tr>
                  <th>hossz hátsó:</th>
                  <td>{hosszH_ts_}</td>
                </tr>
              )}
              {kan_lHossz && (
                <tr>
                  <th>kanál hossz:</th>
                  <td>{kan_lHossz}</td>
                </tr>
              )}
              {kazettatestT_pus && (
                <tr>
                  <th>kazettatest típus:</th>
                  <td>{kazettatestT_pus}</td>
                </tr>
              )}
              {kezeltL_nct_ny_rokSz_ma && (
                <tr>
                  <th>Kezelt lánctányérok száma:</th>
                  <td>{kezeltL_nct_ny_rokSz_ma}</td>
                </tr>
              )}
              {kioszt_s && (
                <tr>
                  <th>kiosztás:</th>
                  <td>{kioszt_s}</td>
                </tr>
              )}
              {kompatibilisF_k && (
                <tr>
                  <th>kompatibilis fék:</th>
                  <td>{kompatibilisF_k}</td>
                </tr>
              )}
              {kompatibilit_s && (
                <tr>
                  <th>kompatibilitás:</th>
                  <td>{kompatibilit_s}</td>
                </tr>
              )}
              {k_nusz__tm_r_ && (
                <tr>
                  <th>kónusz átmérő:</th>
                  <td>{k_nusz__tm_r_}</td>
                </tr>
              )}
              {korm_nyAnyaga && (
                <tr>
                  <th>Kormány anyaga:</th>
                  <td>{korm_nyAnyaga}</td>
                </tr>
              )}
              {korm_nyT_pus && (
                <tr>
                  <th>Kormány típus:</th>
                  <td>{korm_nyT_pus}</td>
                </tr>
              )}
              {korm_nysz_rHossza && (
                <tr>
                  <th>Kormányszár hossza:</th>
                  <td>{korm_nysz_rHossza}</td>
                </tr>
              )}
              {k_z_pr_szTengelyKapcsolat && (
                <tr>
                  <th>középrész tengely kapcsolat:</th>
                  <td>{k_z_pr_szTengelyKapcsolat}</td>
                </tr>
              )}
              {l_nct_ny_rokSz_ma && (
                <tr>
                  <th>Lánctányérok száma:</th>
                  <td>{l_nct_ny_rokSz_ma}</td>
                </tr>
              )}
              {legnagyobbKezeltL_ncker_k && (
                <tr>
                  <th>Legnagyobb kezelt lánckerék:</th>
                  <td>{legnagyobbKezeltL_ncker_k}</td>
                </tr>
              )}
              {legnagyobbL_nct_ny_r && (
                <tr>
                  <th>Legnagyobb lánctányér:</th>
                  <td>{legnagyobbL_nct_ny_r}</td>
                </tr>
              )}
              {lyuksz_m && (
                <tr>
                  <th>lyukszám:</th>
                  <td>{lyuksz_m}</td>
                </tr>
              )}
              {n_gysz_gtengelySzabv_nya && (
                <tr>
                  <th>négyszögtengely szabványa:</th>
                  <td>{n_gysz_gtengelySzabv_nya}</td>
                </tr>
              )}
              {nyeregR_gz_t_se && (
                <tr>
                  <th>nyereg rögzítése:</th>
                  <td>{nyeregR_gz_t_se}</td>
                </tr>
              )}
              {nyeregcs_Anyaga && (
                <tr>
                  <th>Nyeregcső anyaga:</th>
                  <td>{nyeregcs_Anyaga}</td>
                </tr>
              )}
              {nyeregcs_Hossz && (
                <tr>
                  <th>Nyeregcső hossz:</th>
                  <td>{nyeregcs_Hossz}</td>
                </tr>
              )}
              {ped_lCsap_gyaz_sa && (
                <tr>
                  <th>pedál csapágyazása:</th>
                  <td>{ped_lCsap_gyaz_sa}</td>
                </tr>
              )}
              {ped_lKiszerel_s && (
                <tr>
                  <th>pedál kiszerelés:</th>
                  <td>{ped_lKiszerel_s}</td>
                </tr>
              )}
              {ped_lmenet && (
                <tr>
                  <th>pedálmenet:</th>
                  <td>{ped_lmenet}</td>
                </tr>
              )}
              {ped_lt_pus && (
                <tr>
                  <th>pedáltípus:</th>
                  <td>{ped_lt_pus}</td>
                </tr>
              )}
              {peremMagass_g && (
                <tr>
                  <th>perem magasság:</th>
                  <td>{peremMagass_g}</td>
                </tr>
              )}
              {rug_s && (
                <tr>
                  <th>rugós:</th>
                  <td>{rug_s}</td>
                </tr>
              )}
              {sebess_g && (
                <tr>
                  <th>sebesség:</th>
                  <td>{sebess_g}</td>
                </tr>
              )}
              {stopliszabv_ny && (
                <tr>
                  <th>stopliszabvány:</th>
                  <td>{stopliszabv_ny}</td>
                </tr>
              )}
              {sz_less_g && (
                <tr>
                  <th>szélesség:</th>
                  <td>{sz_less_g}</td>
                </tr>
              )}
              {sz_n && (
                <tr>
                  <th>Szín:</th>
                  <td>{sz_n}</td>
                </tr>
              )}
              {sz_g && (
                <tr>
                  <th>szög:</th>
                  <td>{sz_g}</td>
                </tr>
              )}
              {teljesKapacit_s && (
                <tr>
                  <th>teljes kapacitás:</th>
                  <td>{teljesKapacit_s}</td>
                </tr>
              )}
              {tengelyKapcsolat && (
                <tr>
                  <th>Tengely kapcsolat:</th>
                  <td>{tengelyKapcsolat}</td>
                </tr>
              )}
              {tengelyT_pusa && (
                <tr>
                  <th>tengely típusa:</th>
                  <td>{tengelyT_pusa}</td>
                </tr>
              )}
              {tengelyhossz && (
                <tr>
                  <th>tengelyhossz:</th>
                  <td>{tengelyhossz}</td>
                </tr>
              )}
              {v_lt_karBilincs__tm_r_ && (
                <tr>
                  <th>Váltókar bilincs átmérő:</th>
                  <td>{v_lt_karBilincs__tm_r_}</td>
                </tr>
              )}
              {v_lt_karR_gz_t_sHelye && (
                <tr>
                  <th>Váltókar rögzítés helye:</th>
                  <td>{v_lt_karR_gz_t_sHelye}</td>
                </tr>
              )}
              {v_lt_karR_gz_t_sM_dja && (
                <tr>
                  <th>Váltókar rögzítés módja:</th>
                  <td>{v_lt_karR_gz_t_sM_dja}</td>
                </tr>
              )}
              {v_lt_kompatibilit_s && (
                <tr>
                  <th>váltókompatibilitás:</th>
                  <td>{v_lt_kompatibilit_s}</td>
                </tr>
              )}
              {villanyak && (
                <tr>
                  <th>villanyak:</th>
                  <td>{villanyak}</td>
                </tr>
              )}
              {villasaruT_vols_g && (
                <tr>
                  <th>villasaru távolság:</th>
                  <td>{villasaruT_vols_g}</td>
                </tr>
              )}
            </table>
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default PostTemplate

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPost($sLUG: String!) {
    item: googleAlkatrszekSheet(sLUG: { eq: $sLUG }) {
      sKU
      sT_TUSZ
      tERM_KN_V
      kATEG_RIA_1Szint
      kATEG_RIA_2Szint
      kATEG_RIA_3Szint
      kATEG_RIA_4Szint
      bREADCRUMBLV1
      bREADCRUMBLV2
      bREADCRUMBLV3
      bREADCRUMBLV4
      webshop__r
      r_VIDLE_R_S
      sLUG
      id
      fot_11
      fot_19
      fot_18
      fot_17
      fot_15
      fot_16
      fot_14
      fot_13
      fot_12
      fot_110
    }
    tulajdonsagok: googleAlkatrszekSheet(sLUG: { eq: $sLUG }) {
      agyCsap_gyaz_s
      agyKiszerel_s
      agytestAnyaga
      _ll_that_
      _tm_r_
      bCD
      be_p_t_sJellege
      be_p_t_siMagass_g
      befog_
      befog___tm_r_
      beny_l_s
      bilincsHelyzet
      bonthat_L_nct_ny_rok
      bowdenBevezet_s
      bowdent_pus
      bRAND
      csap_gyaz_sFajt_ja
      csap_gyh_z
      cs_szeAnyaga
      els_V_lt_Bilincs__tm_r_
      els_V_lt_Fokozatsz_m
      els_V_lt_R_gz_t_sM_dja
      els_V_lt_karFokozatsz_ma
      fejcs___tm_r_
      f_kKiszerel_s
      f_kKompatibilit_s
      f_kT_pus
      f_kkarBilincs__tm_r_
      f_kkarBowdenvezet_s
      f_kkarKiszerel_s
      f_kkarKompatibilit_s
      f_kkarT_pus
      felhaszn_l_siTer_let
      fogasker_ksorR_gz_t_se
      fogaskoszor_R_gz_t_se
      fogsz_m
      fogsz_mMaxK_l_nbs_g
      fokozatsz_m
      forg_spont
      hajt_karHossz
      h_trahajl_s
      h_ts_V_lt_Fokozatsz_m
      h_ts_V_lt_R_gz_t_sM_dja
      h_ts_V_lt_karFokozatsz_m
      helyzet
      hosszEls_
      hosszH_ts_
      kan_lHossz
      kazettatestT_pus
      kezeltL_nct_ny_rokSz_ma
      kioszt_s
      kompatibilisF_k
      kompatibilit_s
      k_nusz__tm_r_
      korm_nyAnyaga
      korm_nyT_pus
      korm_nysz_rHossza
      k_z_pr_szTengelyKapcsolat
      l_nct_ny_rokSz_ma
      legnagyobbKezeltL_ncker_k
      legnagyobbL_nct_ny_r
      lyuksz_m
      n_gysz_gtengelySzabv_nya
      nyeregR_gz_t_se
      nyeregcs_Anyaga
      nyeregcs_Hossz
      ped_lCsap_gyaz_sa
      ped_lKiszerel_s
      ped_lmenet
      ped_lt_pus
      peremMagass_g
      rug_s
      sebess_g
      stopliszabv_ny
      sz_less_g
      sz_n
      sz_g
      teljesKapacit_s
      tengelyKapcsolat
      tengelyT_pusa
      tengelyhossz
      v_lt_karBilincs__tm_r_
      v_lt_karR_gz_t_sHelye
      v_lt_karR_gz_t_sM_dja
      v_lt_kompatibilit_s
      villanyak
      villasaruT_vols_g
    }
  }
`
